import { MetaLogMessage, useLogger } from 'providers';
import { OrderChangeAction } from 'interfaces/api';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useOrdersSelectors } from './useOrdersStore';

export const useAppendLog = () => {

  const logger = useLogger('OrdersProvider');

  const globalSettings = useOrdersSelectors.globalSettings();

  const logs = useOrdersSelectors.logs();
  const setLogs = useOrdersSelectors.setLogs();

  return useCallback((action: OrderChangeAction, data?: any, info?: string) => {
    logger.debug(action, new MetaLogMessage('data', data));
    setLogs([...(logs || []), { action, data, info, created_at: dayjs().toISOString() }]);
  }, [logger, logs, globalSettings]);

};
