import React, { useCallback, useEffect, useMemo } from 'react';
import { Container, HelgaImage, HelgaImageType, List, ListItem, Loader, Modal, Tag } from 'components';
import { Translate, useTranslate } from 'providers';
import messages from 'messages';
import { useGuard } from 'containers';
import { AggregatedRequirementFlags, DoctorBarcodeMode, Feature, OrderStatus } from 'interfaces/api';
import {
  useBasketContext,
  useBasketSelectors,
  useConvertRequirementToListItem,
  useOrderWizardParametersSelectors,
  useToggleAnalysis,
  useToggleBasket,
} from 'modules/orders/containers/OrderWizard/providers';
import { Color, SortGrouping } from 'interfaces';
import { TooltipPlacement } from 'antd/es/tooltip';
import { RequirementOptions } from 'modules/orders/containers/OrderWizard/components/RequirementOptions';
import cx from 'classnames';
import { SampleList } from 'modules/orders/components';
import {
  useAddRequirement,
  useCurrentOrder,
  useMandatoryFields,
  useOfficeDoctorSelectors,
  useOrderRulesContext,
  useOrdersSelectors,
  useRemoveRequirement,
} from 'modules/orders/providers';
import { usePrevious } from 'react-use';
import { App } from 'antd';
import { difference, filter, find, findIndex, get, includes, map, omit, once } from 'lodash';
import * as Controls from './Controls';
import { useGetTotalPriceLabel } from 'modules/orders/utils';
import { getRequirementSelectedAnalyses, useGetPopover, useInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';
import './styles.less';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { faPencil } from '@fortawesome/pro-light-svg-icons';

export const EmptyBasket = () => (
  <Container grow center>
    <HelgaImage type={HelgaImageType.Basket} width={'103px'}/>
    <span className={'wizard-basket-empty'}>
      <Translate message={messages.orders.wizard.basket.empty}/>
    </span>
  </Container>
);

export const Basket = () => {

  const translate = useTranslate();

  const { message } = App.useApp();

  const guard = useGuard();
  const getPopover = useGetPopover();
  const { getSelectedLabel } = useInvoiceTo();

  useAddRequirement();
  useRemoveRequirement();

  const { basketRequirements, getBasketCount } = useBasketContext();

  const pending = useBasketSelectors.pending();
  const setPending = useBasketSelectors.setPending();

  const toggleBasket = useToggleBasket();
  const toggleAnalysis = useToggleAnalysis();
  const { mandatoryFields } = useMandatoryFields();
  const { loading } = useOrderRulesContext();
  const getTotalPriceLabel = useGetTotalPriceLabel();

  const formGroups = useBasketSelectors.formGroups();
  const orderSamples = useBasketSelectors.orderSamples();
  const { profiles: basketProfiles } = useBasketSelectors.basketProfiles();

  const orders = useOrdersSelectors.orders();
  const immutableRequirementIds = useOrdersSelectors.immutableRequirementIds();

  const currentOrder = useCurrentOrder();

  const globalSettings = useOrdersSelectors.globalSettings();

  const isPoolMode = useOrderWizardParametersSelectors.pool();
  const convertToListItem = useConvertRequirementToListItem();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();

  const realRerequest = guard({ feature: Feature.RealRerequest }, () => orders[0]?.status === OrderStatus.Amendment);

  const previousMandatoryFields = usePrevious(mandatoryFields);
  useEffect(() => {
    const newMandatoryFields = filter(difference(mandatoryFields, previousMandatoryFields));
    const showMissingWarning = once(() => message.warning(translate(messages.orders.missing), 3));
    orders.forEach(order => newMandatoryFields.forEach(field => !get(order, field) && showMissingWarning()));
  }, [mandatoryFields]);

  const basketItems = useMemo(() => formGroups.map((group, index) => {

    return {
      tnr: orders?.[index]?.tnr || group.tnr,
      key: group.formId + '_' + index,
      items: group.requirements.reduce((items, requirement) => {

        requirement = { ...find(basketRequirements, { id: requirement.id }), ...omit(requirement, 'selectedDiagnoses') };

        const form = find(wizardSettings?.forms, { id: requirement.formId });

        if (!form) {
          return items;
        }

        let converted = convertToListItem(requirement, true);

        if (requirement.profileId) {

          if (findIndex(items, i => (i.meta.profileId === requirement.profileId && i.meta.profileType === requirement.profileType)
            && (i.meta.formId === requirement.formId || isPoolMode)
            && i.meta.flags.includes(AggregatedRequirementFlags.IsProfile)) > -1
          ) {
            return items;
          }

          const profile = find(basketProfiles, { profileId: requirement.profileId, profileType: requirement.profileType });

          if (!profile) {
            return items;
          }

          const formRequirements = group.requirements.filter(r => (r.formId === requirement.formId || isPoolMode)
            && (r.profileId === requirement.profileId && r.profileType === requirement.profileType));

          converted = convertToListItem({
            ...profile,
            // requirements: formRequirements,
            formId: form.id,
            shortName: map(formRequirements, r => r.shortName).join(', '),
          }, true);
        }

        if (isPoolMode) {
          const count = getBasketCount(requirement);
          converted.title = count + 'x ' + converted.title;

          const allTag = <Tag className={'wizard-pool-all-badge'} color={Color.Gray}><Translate message={messages.orders.wizard.poolAllPatients}/></Tag>;
          const singleTag = <Tag className={'wizard-pool-single-badge'}><Translate message={messages.orders.wizard.poolSinglePatients}/></Tag>;
          converted.subtitle = <>{count === orders.length ? allTag : singleTag}{converted.subtitle}</>;
        }

        if (wizardSettings?.preferences?.orderWizardPopoverInvoiceTo && requirement.invoiceTo) {
          converted.subtitle = <>{converted.subtitle}, {translate(messages.orders.additionalFields.invoiceToWithValue, { invoiceTo: getSelectedLabel(requirement.invoiceTo) })}</>;
        }

        if (requirement.flags?.includes(AggregatedRequirementFlags.MultiAnalysisSelect)) {

          converted.body = (
            <List
              className={cx('oa-wizard-basket-bak-analyses')}
              onSelect={({ id }) => {
                toggleAnalysis(requirement, id + '', true);
              }}
              items={getRequirementSelectedAnalyses(requirement).map((analysis) => {
                return {
                  id: analysis.shortName,
                  title: analysis.longName,
                };
              })}
            />
          );

        }

        if (requirement.form.isDynamicMaterial && requirement.dynamicMaterials?.length > 0 && !requirement.profileId) {
          converted.body = (
            <List
              className={'basket-dynamic-materials'}
              items={requirement.dynamicMaterials.map((material, idx) => ({ id: idx, title: `#${idx + 1} ${material.text}`, icons: [faPencil] }))}
              onItemClick={() => setPending({ requirement, missing: MissingRequirementOption.DynamicMaterials })}
            />
          );
        }

        const { costUnit, name } = form;
        const formattedFormCostUnit = translate(messages.orders.wizard.basket.formCostUnitFormat, { costUnit, name }) ?? '';

        return [...items, {
          ...converted,
          highlighted: false,
          immutable: includes(immutableRequirementIds, requirement.id),
          groupByValue: isPoolMode ? group.tnr : `${formattedFormCostUnit}` + (group.tnr?.length > 0 ? ` - ${group.tnr}` : ''),
        }];

      }, [] as ListItem[]),
    };

  }), [basketRequirements, formGroups, wizardSettings, toggleAnalysis, basketProfiles]);

  const isIncompatible = useMemo(
    () => basketRequirements.filter(r => currentOrder?.costUnit && r.form?.costUnit !== currentOrder?.costUnit).length === basketRequirements.length,
    [globalSettings, basketRequirements],
  );

  const getGroupHeader = useCallback((group: string, formGroup: { key: string; tnr: string; items: ListItem[] }) => {
    const { key, tnr } = formGroup;

    return (
      (officeDoctor.barcodeMode === DoctorBarcodeMode.PrintFreeBarcodes)
        ? {
          title: group,
          controls: [(
            <Controls.EditBarcodeControl
              key={`ebc_${key}`}
              tnr={tnr}
            />
          )],
        }
        : null
    );
  }, [basketRequirements, officeDoctor]);

  return (

    <Container grow shrink className={'wizard-basket-container'}>

      {basketRequirements.length === 0 && <EmptyBasket/>}

      {basketRequirements.length > 0 && (
        <Container grow shrink>

          <Container grow shrink>

            <Container shrink scrollY style={{ height: 'auto' }}>

              {basketItems.map(formGroup => (
                <Container key={formGroup.key}>
                  <List
                    items={formGroup.items}
                    groupBy={SortGrouping.STRING}
                    className={cx('oa-wizard-list', {
                      'list-item-compact': wizardSettings?.preferences?.orderWizardCompactView,
                      'list-item-ultra-compact': wizardSettings?.preferences?.orderWizardCompactView && wizardSettings?.preferences?.orderWizardUltraCompactView,
                    })}
                    onItemClick={item => !item.immutable && toggleBasket(item.meta, true)}
                    getPopover={item => getPopover(item, true, true, { placement: 'left' as TooltipPlacement })}
                    groupHeader={group => getGroupHeader(group, formGroup)}
                  />
                </Container>
              ))}

              <SampleList order={{ samples: orderSamples, requirements: basketRequirements }} className={'oa-wizard-list'}/>

            </Container>

            {getTotalPriceLabel(basketRequirements, officeDoctor?.localisation)}

          </Container>

          {!isIncompatible && (
            <ul className={'bottom-buttons'}>

              {![OrderStatus.Queued, OrderStatus.Processing].includes(orders[0].status) && (
                <li style={{ order: wizardSettings?.preferences?.ordersPreferScheduleDateMode ? 2 : 1 }}>
                  {realRerequest
                    ? <Controls.SendOffReRequestControl disabled={basketRequirements?.length <= immutableRequirementIds?.length}/>
                    : <Controls.ExecuteOrderControl/>
                  }
                </li>
              )}

              {!realRerequest && (
                <li style={{ order: wizardSettings?.preferences?.ordersPreferScheduleDateMode ? 1 : 2 }}>
                  <Controls.SaveOrderControl/>
                </li>
              )}

              {!realRerequest && ![OrderStatus.Queued, OrderStatus.PrePrint].includes(orders[0].status) && wizardSettings?.preferences?.ordersShowPrePrint && (
                <li style={{ order: 3 }}>
                  <Controls.PreprintOrderControl/>
                </li>
              )}

              {!realRerequest && [OrderStatus.Queued, OrderStatus.PrePrint].includes(orders[0].status) && (
                <li style={{ order: 4 }}>
                  <Controls.CancelOrderControl/>
                </li>
              )}

              {realRerequest && (
                <li style={{ order: 5 }}>
                  <Controls.CancelRealRerequestControl/>
                </li>
              )}

            </ul>
          )}

        </Container>

      )}

      <Modal
        open={!!pending}
        destroyOnClose
        footer={null}
        component={RequirementOptions}
        onCancel={() => setPending(undefined)}
      />

      {loading && <Loader overlay/>}

    </Container>
  );

};
