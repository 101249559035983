import React, { useCallback } from 'react';
import { AggregatedProfileProperties, AggregatedRequirementFlags, OrderChangeAction, OrderWizardRequirement, WriteableOrderProperties } from 'interfaces/api';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { filter, find, findIndex, findLastIndex, forEach, includes, keys, uniq } from 'lodash';
import { getRequirementSelectedAnalyses, isProfile } from 'modules/orders/containers/OrderWizard/utils';
import { useInfoModal, useWarningModal } from 'components';
import messages from 'messages';
import {
  useAppendLog,
  useApplyOrderDefaultValues,
  useCurrentOrder,
  useIncompatibleRequirements,
  useOfficeDoctorSelectors,
  useOrdersSelectors,
  useSetOrders,
} from 'modules/orders/providers';
import { useBasketContext, useBasketSelectors, useRequirementFilteredForms, useSaveOrExecuteOrders } from 'modules/orders/containers/OrderWizard/providers/BasketProvider';
import { App } from 'antd';
import { arrayify } from 'utils/helpers';
import { useTranslate } from 'providers';
import { useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers/ParametersProvider';
import { useProfileRequirements, useSuperSubRequirements } from 'modules/orders/utils';

export const useUpsertBasket = () => {

  const translate = useTranslate();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const { profiles } = useBasketSelectors.basketProfiles();
  const getProfileRequirements = useProfileRequirements();
  const getSuperSubRequirements = useSuperSubRequirements();

  const { setAllOrSelectedOrders } = useSetOrders();

  const applyOrderDefaultValues = useApplyOrderDefaultValues();

  const currentOrder = useCurrentOrder();
  const appendLog = useAppendLog();

  const orders = useOrdersSelectors.orders();
  const latestSelectedFormId = useOrdersSelectors.latestSelectedFormId();
  const setLatestSelectedFormId = useOrdersSelectors.setLatestSelectedFormId();

  const isPoolMode = useOrderWizardParametersSelectors.pool();

  const { isInBasket, inBasketAsDuplicateRequirement, basketRequirements } = useBasketContext();

  const setPending = useBasketSelectors.setPending();
  const setShowRules = useBasketSelectors.setShowRules();

  const saveOrExecute = useSaveOrExecuteOrders();
  const filterForms = useRequirementFilteredForms();
  const getIncompatibleRequirements = useIncompatibleRequirements();

  const warningModal = useWarningModal();
  const infoModal = useInfoModal();
  const { message } = App.useApp();

  // check for incompatible requirement and show warning modal
  const checkIncompatible = useCallback((requirement: OrderWizardRequirement | OrderWizardRequirement[], showWarning?: boolean) => {
    const incompatibleRequirements = arrayify(requirement).filter(r => getIncompatibleRequirements(r.shortName).length > 0);
    if (incompatibleRequirements.length > 0 && showWarning) {
      warningModal({
        title: translate(messages.orders.wizard.basket.incompatible.title),
        content: (
          <div>
            {incompatibleRequirements.map(r => (
              <p dangerouslySetInnerHTML={{
                __html: translate(messages.orders.wizard.basket.incompatible.content, {
                  shortName: r.shortName,
                  incompatible: getIncompatibleRequirements(r.shortName).join(', '),
                }),
              }}/>
            ))}
          </div>
        ),
      });
    }
    return incompatibleRequirements.length === 0;
  }, [getIncompatibleRequirements, translate]);

  // check for duplicate requirement and show warning modal
  const checkDuplicateRequirement = useCallback((requirement: OrderWizardRequirement | OrderWizardRequirement[], showWarning?: boolean) => {
    const duplicateRequirements = arrayify(requirement).filter(r => inBasketAsDuplicateRequirement(r).length > 0);
    if (duplicateRequirements.length > 0 && showWarning) {
      warningModal({
        title: translate(messages.orders.wizard.basket.duplicateRequirements.title),
        content: (
          <div>
            {duplicateRequirements.map(r => (
              <p dangerouslySetInnerHTML={{
                __html: translate(messages.orders.wizard.basket.duplicateRequirements.content, {
                  duplicate: inBasketAsDuplicateRequirement(r).map(r => r.shortName).join(', '),
                }),
              }}
              />
            ))}
          </div>
        ),
      });
    }
    return duplicateRequirements.length === 0;
  }, [inBasketAsDuplicateRequirement, translate]);

  // check for requirement already in basket and show warning modal
  const checkAlreadyInBasketRequirement = useCallback((requirement: OrderWizardRequirement | OrderWizardRequirement[], showWarning?: boolean) => {
    const alreadyInBasketRequirements = arrayify(requirement).filter(r => isInBasket(r));
    if (alreadyInBasketRequirements.length > 0 && showWarning) {
      warningModal({
        title: translate(messages.orders.wizard.basket.alreadyInBasketRequirements.title),
        content: (
          <div>
            {alreadyInBasketRequirements.map((r) => {
              const { profileId, profileType } = find(basketRequirements, { id: r.id });
              const inProfile = find(profiles, { profileId, profileType });
              return (
                <p dangerouslySetInnerHTML={{
                  __html: translate(messages.orders.wizard.basket.alreadyInBasketRequirements[inProfile ? 'contentWithProfile' : 'content'], {
                    requirement: r.shortName,
                    profile: inProfile?.longName,
                  }),
                }}
                />
              );
            })}
          </div>
        ),
      });
    }
    return alreadyInBasketRequirements.length === 0;
  }, [profiles, isInBasket, translate, basketRequirements]);

  /**
   * set requirement form
   */
  const getRequirementForm = useCallback((requirement: OrderWizardRequirement, order?: WriteableOrderProperties) => {

    const filteredForms = filterForms(requirement, (order || currentOrder)?.costUnit, isPoolMode);
    const defaultForms = filter(filteredForms, id => find(wizardSettings?.forms, { id })?.isDefault);

    let formId = isPoolMode ? undefined : requirement.formId;

    if (!formId) {
      if (filteredForms.length === 1 || isPoolMode) {
        formId = filteredForms[0];
      } else if (defaultForms.length === 1) {
        formId = defaultForms[0];
      } else if (filteredForms.includes(latestSelectedFormId)) {
        formId = latestSelectedFormId;
      }
    }

    return find(wizardSettings?.forms, { id: formId });

  }, [filterForms, wizardSettings, latestSelectedFormId, isPoolMode, currentOrder]);

  /**
   * check missing requirement options
   */
  const checkMissingRequirementOptions = useCallback((requirement: OrderWizardRequirement) => {

    const form = getRequirementForm(requirement);

    // ask form id
    if (!form) {
      return setPending({ requirement, missing: MissingRequirementOption.Form });
    }

    // select dynamic materials
    if (form.isDynamicMaterial && !requirement.dynamicMaterials?.length) {
      return setPending({ requirement: { ...requirement, dynamicMaterials: [{ text: '' }] }, missing: MissingRequirementOption.DynamicMaterials });
    }

    // select analyses
    if (!requirement.selectedAnalyses?.length && requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect)) {
      if (requirement.analyses.length === 1) {
        requirement.selectedAnalyses = [requirement.analyses[0].shortName];
      } else {
        return setPending({ requirement, missing: MissingRequirementOption.Analyses });
      }
    }

    // ask analysis freetext
    if (getRequirementSelectedAnalyses(requirement).filter(a => a.askAnalysisFreeText).length && !requirement.analysisFreeText) {
      return setPending({ requirement, missing: MissingRequirementOption.AnalysisFreeText });
    }

    // ask left/right
    if (requirement.leftRight === undefined && requirement.flags.includes(AggregatedRequirementFlags.AskLeftRight)) {
      return setPending({ requirement, missing: MissingRequirementOption.LeftRight });
    }

    // ask intraoperative
    if (requirement.intraoperative === undefined && requirement.flags.includes(AggregatedRequirementFlags.AskIntraoperative)) {
      return setPending({ requirement, missing: MissingRequirementOption.Intraoperative });
    }

    // ask origin
    if (!requirement.origin && requirement.flags.includes(AggregatedRequirementFlags.AskOrigin)) {
      return setPending({ requirement, missing: MissingRequirementOption.Origin });
    }

    // ask localization
    if (requirement.localizations.length > 0 && !requirement.selectedLocalizations?.length) {
      return setPending({ requirement, missing: MissingRequirementOption.Localization });
    }

    return true;

  }, [
    setPending,
    getRequirementForm,
  ]);

  /**
   * apply requirement to order
   */
  const applyRequirementToOrder = useCallback((order: WriteableOrderProperties, requirement: OrderWizardRequirement): WriteableOrderProperties => {

    const form = getRequirementForm(requirement, order);

    if (!form) {
      return order;
    }

    setLatestSelectedFormId(form.id);

    const lastFormIndex = findLastIndex(order.requirements, { formId: form.id });
    const insertAtIndex = lastFormIndex >= 0 ? lastFormIndex + 1 : order.requirements.length + 1;

    const req = { ...requirement, form, formId: form.id };

    if (findIndex(order.requirements, { id: requirement.id }) > -1) {
      // replace requirement in basket
      order.requirements = order.requirements.map(r => r.id === requirement.id
        ? { ...r, ...req, selectedAnalyses: uniq([...(r.selectedAnalyses || []), ...(req.selectedAnalyses || [])]) }
        : r);
    } else {

      if (wizardSettings?.preferences.orderWizardSubRequirementsInBasket) {
        // remove duplicate requirements
        order.requirements = order.requirements.filter((r) => {
          if (r.id !== req.id && r.laboratoryGroup === req.laboratoryGroup && req.duplicateRequirements.includes(r.shortName)) {
            warningModal({
              title: translate(messages.orders.wizard.basket.duplicateRemoved.title),
              content: translate(messages.orders.wizard.basket.duplicateRemoved.content, { shortName: req.shortName, duplicate: r.shortName }),
            });
            return false;
          }
          return true;
        });
      }

      // add requirement at index
      order.requirements = [
        ...order.requirements.slice(0, insertAtIndex),
        req,
        ...order.requirements.slice(insertAtIndex),
      ];
    }

    return order;

  }, [getRequirementForm, setLatestSelectedFormId, wizardSettings?.preferences.orderWizardSubRequirementsInBasket]);

  /**
   * upsert profile
   */
  const upsertProfile = useCallback((profile: AggregatedProfileProperties, instantExecution?: boolean) => {

    const profileCount = profiles.length || 0;

    const profileRequirements = getProfileRequirements(profile);

    checkIncompatible(profileRequirements, true);
    checkDuplicateRequirement(profileRequirements, true);
    checkAlreadyInBasketRequirement(profileRequirements, wizardSettings?.preferences?.orderWizardShowProfileAlreadyInBasketWarning);

    const requirements = profileRequirements
      .filter(r => checkIncompatible(r))
      .filter(r => checkDuplicateRequirement(r))
      .filter(r => !wizardSettings?.preferences?.orderWizardShowProfileAlreadyInBasketWarning || checkAlreadyInBasketRequirement(r))
    ;

    const mapOrders = (order: WriteableOrderProperties): WriteableOrderProperties => {

      if (!profileCount) {
        if (profile.orderReason) {
          order.patient.orderReason = profile.orderReason;
        }

        if (profile.diagnosis) {
          order.patient.diagnosis = profile.diagnosis;
        }

        if (keys(profile.selectedDiagnoses).length > 0) {
          order.selectedDiagnoses = profile.selectedDiagnoses;
        }
      }

      if (profile.freeText) {
        order.freeText = order.freeText?.length ? `${order.freeText};${profile.freeText}` : profile.freeText;
      }

      return requirements.reduce((order, requirement) => {
        if (requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect) && isInBasket(requirement)) {

          const { selectedAnalyses, localizations, longName } = find(order.requirements, { id: requirement.id });

          const localizationsOverwritten = localizations?.length && requirement.localizations?.length;

          if (localizationsOverwritten) {
            infoModal({
              title: translate(messages.orders.wizard.basket.profileDissolvedNewLocalisations.title),
              content: translate(messages.orders.wizard.basket.profileDissolvedNewLocalisations.content, { longName, profileName: profile.longName }),
            });
          } else {
            message.open({ type: 'info', content: translate(messages.orders.wizard.basket.profileDissolved, { longName }) });
          }

          return applyRequirementToOrder(order, { ...requirement, selectedAnalyses: uniq([...selectedAnalyses, ...requirement.selectedAnalyses]), profileId: null });
        }
        return applyRequirementToOrder(order, { ...requirement, profileId: profile.profileId, profileType: profile.profileType });
      }, order);
    };

    appendLog(OrderChangeAction.AddProfileRequirement, profile);

    if (instantExecution) {
      // instant execution
      saveOrExecute({ execute: true }, orders.map(mapOrders).map(applyOrderDefaultValues));
    } else {

      const requirementRules = requirements.filter(r => r.ruleInfoText?.length > 0 && !isInBasket(r));

      setAllOrSelectedOrders(mapOrders);

      if (requirementRules.length > 0) {
        setShowRules(requirementRules);
      }

      setLatestSelectedFormId(requirements[0].formId);
    }

  }, [
    applyRequirementToOrder,
    wizardSettings,
    profiles,
    checkIncompatible,
    checkDuplicateRequirement,
    setAllOrSelectedOrders,
    setLatestSelectedFormId,
    setShowRules,
    appendLog,
    saveOrExecute,
    applyOrderDefaultValues,
    isInBasket,
    getProfileRequirements,
  ]);

  /**
   * upsert requirement
   */
  const upsertRequirement = useCallback((requirement: OrderWizardRequirement) => {

    if (checkMissingRequirementOptions(requirement) !== true) {
      return;
    }

    // currently not in basket
    if (!isInBasket(requirement) && requirement.ruleInfoText?.length > 0) {
      // show requirement rule
      setShowRules([requirement]);
    }

    // add requirement to orders
    setAllOrSelectedOrders(order => applyRequirementToOrder(order, requirement));

    setPending(undefined);

    appendLog(OrderChangeAction.AddRequirement, requirement);

  }, [
    checkMissingRequirementOptions,
    applyRequirementToOrder,
    appendLog,
    setAllOrSelectedOrders,
    setPending,
    isInBasket,
    setShowRules,
  ]);

  /**
   * main upsert basket function
   */
  return useCallback((requirement: OrderWizardRequirement | AggregatedProfileProperties, instantExecution?: boolean) => {

    // upsert profile
    if (isProfile(requirement)) {
      return upsertProfile(requirement, instantExecution);
    }

    if (!checkIncompatible(requirement, true) || !checkDuplicateRequirement(requirement, true)) {
      return;
    }

    upsertRequirement(requirement);

    if (requirement.flags.includes(AggregatedRequirementFlags.IsSuperRequirement)) {

      requirement.formId ??= getRequirementForm(requirement)?.id;

      forEach(getSuperSubRequirements(requirement), (r) => {
        if (includes(r.forms, requirement.formId)) {
          upsertRequirement({ ...r, formId: requirement.formId });
        }
      });
    }

  }, [
    upsertRequirement,
    upsertProfile,
    checkIncompatible,
    checkDuplicateRequirement,
    wizardSettings,
    getRequirementForm,
    getSuperSubRequirements,
  ]);

};
